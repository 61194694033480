<template>
  <div class="all-rule-list">
    <div class="libraryName">{{ dataForm.libraryName }}</div>
    <el-form :inline="true">
      <div style="display: flex; margin: 20px 0px">
        <el-form-item>
          <el-button type="primary" @click="exportList">导出</el-button>
        </el-form-item>
      </div>
    </el-form>

    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        type="index"
        header-align="center"
        align="center"
        label="序号"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="collectionNum"
        header-align="center"
        align="center"
        label="填写记录号"
      >
      </el-table-column>
      <el-table-column
        prop="createName"
        header-align="center"
        align="center"
        width="200"
        label="填写人"
      >
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        width="200"
        label="填写时间"
      >
      </el-table-column>
      <el-table-column
        prop="updateTime"
        header-align="center"
        align="center"
        label="操作"
        width="100"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="handleViewInfo(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      libraryId: "",
      dataForm: {},
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      searchContent: "",
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.libraryId = this.$route.query.id;
      this.getDataInfo();
      this.getDataList();
    }
  },
  methods: {
    //指标库详情
    getDataInfo() {
      this.$http({
        url: this.$http.adornUrl("/indexLibrary/info?id=" + this.libraryId),
        method: "get",
      }).then(({ data }) => {
        if (data.status) {
          this.dataForm = data.data.indexLibraryDao;
        }
      });
    },
    getDataList() {
      this.dataListLoading = true;
      this.$http({
        url: this.$http.adornUrl("/collectionInfo/list"),
        method: "get",
        params: {
          pageSize: this.pageSize,
          pageNo: this.pageIndex,
          libraryId: this.libraryId,
        },
      }).then(({ data }) => {
        if (data.status) {
          this.dataList = data.data;
          this.totalPage = data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    handelChange(val) {
      this.getDataList();
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    handleViewInfo(row) {
      // this.$router.push({
      //   path: "/dataCollect/recordInfo",
      //   query: {
      //     id: row.id,
      //   },
      // });
      const href =
        this.$global.dominH5 +
        "dataCollectInfo2.html?id=" +
        this.libraryId +
        "&infoId=" +
        row.id;
      window.open(href);
    },
    // 导出列表
    exportList() {
      var that = this;
      let link = document.createElement("a");
      // link.href =
      //   this.$global.baseURL +
      //   "/collectionInfo/downloadItems?libraryId=" +
      //   that.libraryId;
      link.href =this.$http.adornUrl('/collectionInfo/downloadItems?libraryId='+this.libraryId)
        
      link.click();
    },
  },
};
</script>

<style lang="scss">
.libraryName {
  padding: 10px 0 30px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}
.all-rule-list {
  .search-top {
    padding-bottom: 20px;
    overflow: hidden;

    .search-btn {
      font-size: 20px;
      cursor: pointer;
    }
  }

  .share-box {
    line-height: 3em;
  }
  .copy-box {
    display: flex;
    justify-content: space-between;
    .qrcode {
      width: 200px;
      height: 200px;
    }
  }
}
</style>
